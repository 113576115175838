import React from "react";
import "./Footer.scss";
// import logo3 from "../../images/casinon.png"
import logo4 from "../../images/logoBlue.png";
// import logo5 from "../../images/Bild5.png"
import logo6 from "../../images/CasinoBurst.png";
import logo7 from "../../images/cug-logo.png";
import logo8 from "../../images/spelpressen.png";

import streamlyLogo from "../../images/logos/streamly-text-and-image-logo-light-gray.svg";
import {isStreamHomePage} from "../../utils/Util";
import {getUrlWithCountry} from "../../utils/UrlUtil";

function Footer() {
  return (
    <div>
      <footer className="footer">
        <div className="container">
          {isStreamHomePage() ? (
            <section className="sponsorBanner">
              <span className="title">Samarbeten</span>
              <ul>
                <li>
                  <a
                    href="https://casinoutangränser.se/utlandska-casinon/"
                    target="_blank"
                  >
                    <img
                      src={logo7}
                      alt="utländska casinon"
                    />
                  </a>
                </li>
                {/* <li>
                  <a href="https://xn--utlndskacasino-7hb.net/" target="_blank">
                    <img src={logo2} alt="Bäst utländska casinon utan licens" />
                  </a>
                </li> */}
                <li>
                                <a href="https://spelpressen.se/" target="_blank">
                                    <img src={logo8} alt="Casino utan svensk licens" />
                                </a></li>
                <li>
                  <a
                    href="https://casinoburst.com/"
                    target="_blank"
                  >
                    <img
                      src={logo6}
                      alt="banner-casinoburst-casinon-utan-spelpaus"
                    />
                  </a>
                </li>

                <li>
                  <a href="https://casinoutansvensklicens.co/" target="_blank">
                    <img src={logo4} alt="casinoutansvensklicens.co" />
                  </a>
                </li>
              </ul>
            </section>
          ) : null}

          <div className="footer-menu">
            <div className="row">
              <div className="col-xs-4 top">
                <h2>Om Streamly.com</h2>
                <p>
                Sveriges bästa streamingsajt heter Streamly.com. Här hittar du utbudet från alla streamingtjänster
så som Netflix, HBO Max, Viaplay, Sky Showtime, Amazon Prime, TV4, SVT Play som ger dig full
översikt över vilka filmer och serier som visas. Tjänsten drivs inom Comspace group, som också
ligger bakom sajter som <a href="https://kolla.tv/" target="_blank">Kolla.tv</a> och <a href="https://dagenstv.com/" target="_blank">Dagenstv.com</a>.
                  
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12">
                <h2>Våra kategorier:</h2>
              </div>
            </div>
            <div className="row justify">
              <div className="col-xs-4 col-sm-9">
                <div className="row">
                  <div className="col-xs-4 col-sm-4">
                    <h3>
                    <a href={getUrlWithCountry("/genre/movies")}>Film</a>
                     
                    </h3>
                    <p>
                    Här har vi samlat en översikt över vilka filmer som alla streamingtjänster erbjuder. Du kan också få
tips på filmer genom att läsa våra populära artiklar.
                    </p>
                  </div>
                  <div className="col-xs-4 col-sm-4">
                    <h3>
                      <a href={getUrlWithCountry("/genre/series")}>Serier</a>
                    </h3>
                    <p>
                    I denna kategori hittar du en stort utbud på olika serie, som de olika streaming tjänsterna erbjuder
Från reality till drama, nya till gamla serier.
                    </p>
                  </div>
                  {/* <div className="col-xs-4 col-sm-4">
                    <h3>
                    <a href={getUrlWithCountry("/sports")}>Sport</a>
                    </h3>
                    <p>
                    Vad vore livet utan sport och genom vår snabba filtrering får du snabbt en överblick kring varje dags
olika sportsändningar som finns tillgängliga att streama online live eller se i efterhand.
                    </p>
                  </div> */}

                  <div className="col-xs-4 col-sm-4">
                    <h3>
                      <a href="https://streamly.com" target="_blank">
                          Topplistor
                      </a>
                    </h3>
                    <p>
                    Vi har samlat alla topplistor från de populära streamingtjänsterna som Netflix, HBO Max, Viaplay,
Sky Showtime, Amazon Prime, TV4, SVT Play m.fl. Se vart du kan titta online på din favoritfilm eller
serie. Streamly.com är den ultimata guiden.
                    </p>
                  </div>
                  <div className="col-xs-4 col-sm-4">
                    <h3>
                    <a href={getUrlWithCountry("/genre/articles")}> Artiklar</a>
                    </h3>
                    <p>
                    Här rapporterar vi om dagsaktuella händelser inom streamingmarknaden. Vi tipsar också om
intressanta produktioner och premiärer som du inte får missa.
                    </p>
                  </div>
                  <div className="col-xs-4 col-sm-4">
                    <h3>
                     
                      <a href="/Annonsera">Annonsera</a>
                    </h3>
                    <p>
                    Är du intresserad att synas och annonsera på Streamly.com? Vi har många spännande upplägg och
möjligheter.
                    </p>
                  </div>
                </div>
              </div>
            
            <div className="col-xs-4 col-sm-3">
            <div className="logo-container">
            <img src={streamlyLogo} alt="Streamly" className="footer-logo" />
            <small>© 2022 Streamly International AB</small>
          </div>
          <div className="link-container">
            <small>
              <a href="/page/about">Om Streamly</a> |
              <a href="/page/cookies">Cookies</a> |
              <a href="/page/terms">Användarvillkor</a>
            </small>
          </div>
            </div>
            
            </div>
          </div>

         
        </div>
      </footer>
    </div>
  );
}

export default Footer;
